<template>
	<v-data-table
		class="has-border"
		disable-sort
		:headers="headers"
		:items="listDomain"
		:search="search"
	>
		<template v-slot:[`item.domain`]="{item}">
			<a
				class="cursor-pointer primary--text"
				target="_blank"
				:href="`https://${item.domain}`"
				>{{ item.domain }}</a
			>
		</template>
		<template v-slot:[`item.updatedAt`]="{item}">
			{{ moment(item.updatedAt).format('DD/MM/YYYY') }}
		</template>
		<template v-slot:[`item.status`]="{item}">
			<v-tooltip top>
				<template v-slot:activator="{on, attrs}">
					<v-btn
						v-bind="attrs"
						v-on="on"
						icon
						:color="item.status === 'success' ? 'green' : 'red'"
						@click="() => onChangeStatus(item)"
					>
						<v-icon small>{{
							item.status === 'locked' ? 'mdi-lock' : 'mdi-account-check'
						}}</v-icon>
					</v-btn>
				</template>
				<span
					:style="`color: ${item.status === 'success' ? 'green' : 'gray'}`"
					>{{ item.status }}</span
				>
			</v-tooltip>
		</template>
		<template v-slot:[`item.actions`]="{item}">
			<div class="d-flex justify-end">
				<v-btn
					icon
					color="primary"
					@click="$router.push(`/domain-detail/${item.id}`)"
				>
					<v-icon small>mdi-pencil-outline</v-icon>
				</v-btn>
			</div>
		</template>
	</v-data-table>
</template>
<script>
import {mapActions, mapGetters} from 'vuex'
import moment from 'moment'
import get from 'lodash-es/get'
export default {
	props: {
		search: String
	},
	computed: {
		...mapGetters({
			vendors: 'vendor/getVendors'
		}),
		listDomain() {
			return this.vendors.map(item => {
				const domain = get(item, 'learn.hostname', null)
				const status = get(item, 'status', 'success')
				const crPackage = get(item, 'usingPackage', null)
				const capacityS3 = this.$utils.formatBytes(get(item, 'report.sizeS3', 0))
				const activeStudents = get(item, 'report.activeStudents')
				return {
					id: item.id,
					domain: domain,
					updatedAt: item.updatedAt,
					status,
					capacity: capacityS3,
					activeStudents: activeStudents,
					package: crPackage
						? this.$utils.formatPackage(crPackage.package)
						: '',
					expiredTime: crPackage
						? moment(new Date(crPackage.expiredTime)).format('DD/MM/YYYY')
						: ''
				}
			})
		}
	},
	methods: {
		...mapActions({
			updateVendor: 'vendor/updateVendor'
		}),
		async onChangeStatus(item) {
			await this.$confirm.show({
				confirmColor: 'primary',
				title: 'Cập nhật trạng thái khách hàng',
				text: 'Cập nhật trạng thái khách hàng',
				done: async () => {
					this.$message.loading('Đang cập nhật')
					await this.updateVendor({
						id: item.id,
						status: item.status === 'success' ? 'locked' : 'success'
					})
					this.$message.success('Cập nhật thành công')
				}
			})
		}
	},
	data() {
		return {
			moment,
			get,
			headers: [
				{text: 'Tên miền chính', value: 'domain', width: '15%'},
				{text: 'Trạng thái', value: 'status', width: '8%', align: 'center'},
				{text: 'Gói sử dụng', value: 'package', width: '10%', align: 'center'},
				{
					text: 'Học viên active',
					value: 'activeStudents',
					width: '10%',
					align: 'center'
				},
				{
					text: 'Dung lượng S3',
					value: 'capacity',
					width: '10%',
					align: 'center'
				},
				{
					text: 'Thời gian hết hạn',
					value: 'expiredTime',
					width: '15%',
					align: 'center'
				},
				{text: 'Cập nhật', value: 'updatedAt', width: '10%', align: 'center'},
				{text: 'Thao tác', value: 'actions', width: '10%', align: 'end'}
			]
		}
	}
}
</script>
