<template>
	<Domain />
</template>
<script>
import Domain from '@/modules/Domain/index.vue'
export default {
	components: {
		Domain,
	},
}
</script>
