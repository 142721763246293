<template>
	<BasePageLayout>
		<div slot="page__title">Quản lý tên miền</div>
		<div class="pa-8">
			<DomainFilter
				@search="onSearch"
				@create="$refs['create-dialog'].openDialog()"
			/>
			<DomainTable :search="search" />
		</div>
		<CreateClientDialog ref="create-dialog" @refresh="refresh" />
	</BasePageLayout>
</template>
<script>
import {mapActions} from 'vuex'
import BasePageLayout from '@/templates/layout/BasePageLayout'
import DomainFilter from '@/modules/Domain/components/DomainFilter'
import DomainTable from '@/modules/Domain/components/DomainTable'
import CreateClientDialog from '@/modules/Clients/components/CreateClientDialog'
export default {
	components: {
		BasePageLayout,
		DomainFilter,
		DomainTable,
		CreateClientDialog
	},
	data() {
		return {
			search: ''
		}
	},
	async created() {
		this.refresh()
	},
	methods: {
		...mapActions({
			fetchVendors: 'vendor/fetchVendors'
		}),
		async refresh() {
			this.$message.loading('Đang lấy dữ liệu ...')
			await this.fetchVendors({
				vendorId: 'main-page'
			})
			this.$message.close()
		},
		onRemove(domainId) {
			this.$confirm.show({
				title: 'Bạn có chắc chắn muốn xoá tên miền này?',
				text:
					'Dữ liệu sau khi xóa không thể khôi phục. Bạn có chắc chắn muốn xóa tên miền này?',
				done: async () => {
					await this.removeDomain(domainId)
				}
			})
		},
		onSearch(search) {
			this.search = search
		}
	}
}
</script>
